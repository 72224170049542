/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    animateFinish: false,
    played: false,
    info: {},
    desc: [],
    text: [],
    histories: [],
    slogan: '',
  },
  mutations: {
    setAnimateFinish(s, value) {
      s.animateFinish = value;
      if (value) {
        s.played = true;
      }
    },
    setInfo(s, value) {
      s.info = value;
    },
    setIntro(s, data) {
      s.histories = data?.timeline || [];
      if (data?.introduction) {
        s.desc = data?.introduction.split('\n');
      }
      if (data?.description) {
        s.text = data?.description.split('\n');
      }
      if (data?.slogan) {
        s.slogan = data.slogan;
      }
    },
  },
  getters: {
    animateFinish: (s) => s.animateFinish,
    played: (s) => s.played,
    info: (s) => s.info,
    desc: (s) => s.desc,
    text: (s) => s.text,
    slogan: (s) => s.slogan,
    histories: (s) => s.histories,
  },
  actions: {
  },
  modules: {
  },
});
