<template lang="pug">
.workflow-step.card-style.flex.align-stretch.justify-stretch.no-select(
  :class="{'show-more': hasMore}")
  .step-container.flex.flex-1.column.align-start.pl-26.pr-20.pb-16.pt-4
    .step {{ step }}
    .split-line
    .title.flex.align-end.mt-12.justify-space-between
      .title-text.text-left {{ title }}
      .more-icon(v-if="hasMore")
    .more.flex.column.mt-16.justify-start.text-left
      ul
        li(v-for="t in more", :key="t") {{ t }}
</template>

<script>
export default {
  name: 'workflow-step',
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    more: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.workflow-step {
  width: 225px;
  max-width: 225px;
  flex: 0 0 225px;
  height: 175px;
  overflow: visible;
  position: relative;
  .step-container {
    max-height: 175px;
    overflow: hidden;
    transition: all .3s ease-in-out;
  }
  .step {
    font-family: Poppins;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
  }
  .split-line {
    flex: 0 0 3px;
    width: 100%;
    background-color: $color-primary;
  }
  .title {
    word-break: keep-all;
    flex: 0 0 54px;
    width: 100%;
    .title-text {
      flex: 0 0 128px;
      font-family: Noto Sans TC;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.27px;
    }
    .more-icon {
      border-bottom: 3px solid #7A7979;
      border-right: 3px solid #7A7979;
      min-width: 18px;
      min-height: 18px;
      flex: 0 0 18px;
      transform-origin: 50% 50%;
      transform: translateY(-10px) rotate(45deg);
      transition: all .3s ease-in-out;
    }
  }
  .more {
    opacity: 0.01;
    font-weight: 400;
    font-size: 15px;
    ul {
      list-style-position: outside;
      padding-left: 8px;
      line-height: 170%;
    }
    .row {
      flex: 0 0 26px;
    }
  }
  &:hover {
    .step-container {
      background-color: $color-primary;
      border-radius: 13px;
      .split-line {
        background-color: white;
      }
    }
  }
  &.show-more:hover {
    z-index: 10;
    .more {
      opacity: 1;
    }
    .step-container {
      position: absolute;
      width: 225px;
      height: auto;
      max-height: 380px;
      .title {
        .more-icon {
          transform: rotate(-135deg);
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .workflow-step {
    flex: 1 0 375px;
    height: 146px;
    max-width: calc(100vw - 40px);
    .step-container {
      max-height: 142px;
    }
    .step {
      font-size: 40px;
    }
    .split-line {
      margin-top: 8px;
    }
    .title {
      margin-top: 0px !important;
      .title-text {
        white-space: nowrap;
      }
    }

    &.show-more:hover {
      .step-container {
        position: absolute;
        width: 100%;
      }
    }
  }
}
</style>
