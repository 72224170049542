<template lang="pug">
.home.flex.column(@scroll.lazy="checkPos", :class="{ animate: !animateFinish }")
  .main-part(:class="{ 'show-animate': !animateFinish }")
    .animate.flex.column.align-center.justify-center(:class="[animateStep]")
      LogoSvg.animate-logo
      img.animate-name.mt-20(src="/svg/name.svg")
      img.animate-name-tw.rwd-hide.mt-100(src="/svg/name-tw.svg")
      img.animate-name-tw.rwd-only.mt-100(src="/svg/name-tw-rwd.svg")
      .bg
        .left-bg.flex.column
          img(src="/svg/bg.svg")
          img(src="/svg/bg.svg")
        .right-bg.flex.column
          img(src="/svg/bg.svg")
          img(src="/svg/bg.svg")
      a(href="#main")
        .arrow.clickable
    .data.flex.column.align-start.justify-end.gap-20
      .title
        span 專注細節
        span.rwd-hide &nbsp;構築卓越
        span.rwd-only
          span 構築卓越
      .sub-title In our pursuit of perfection, we attain excellence.
      .button.clickable.flex.align-center.justify-center.mt-20(
        @click="$router.push('/introduction')"
      ) 關於TOPTEAM
  #main.photos.flex.column(
        @mousemove="doDrag"
        @mouseup="endDrag"
        @touchmove="doDrag"
        @touchend="endDrag")
    .row.flex.align-center(ref="firstRow")
      .photos-title 從設計規劃到完工面面俱到
      .splitter-container(
        :style="photoSplitterStyle"
        @mousedown="startDrag"
        @touchstart="startDrag"
        )
        img.splitter(
          :draggable="false"
          src="/svg/splitter.svg")
      .photo-container.left(:style="photoLeftStyle")
        img.photo.flex-1(src="/images/home-1-line.jpeg")
      .photo-container.right(:style="photoRightStyle")
        img.right.photo.flex-1(src="/images/home-1.jpg")
  workflow.pt-72.pb-110(ref="workflow")
  .logo-wall.flex.column.align-center.pt-84.pb-110
    block-title(title="代表性實績", small)
    .logos.flex.align-center.wrap
      template(v-for="i in logoHeight")
        template(v-for="j in logoWidth")
          .logo-cell.flex.row.align-center.justify-center
            img.logo-table-img.clickable(
              @click="goProject(i, j)",
              :src="`/images/logos/${i}-${j}-color.jpg`"
            )
    //- .logo-table.flex.column.mt-50
    //-   .logo-table-row.flex.align-stretch(v-for="i in logoHeight")
    //-     .logo-table-cell.flex.row.align-center.justify-center(v-for="j in logoWidth")
    //-       img.logo-table-img(:src="`/images/logos/${i}-${j}.png`")
    .big-cards.flex.align-start.mt-148.gap-60
      big-card(
        v-for="(info, idx) in bigCardInfo",
        :key="idx",
        :background="info.background",
        :page="info.page",
        :image="info.image",
        :title="info.title",
        :desc="info.desc"
      )
  page-footer
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { GetCompanyLogoPos, GetCompanyLogoProjects } from '@/api/company';
import LogoSvg from '@/components/svg/logo.vue';
import WorkFlow from '../components/home/WorkFlow.vue';
import BigCard from '../components/home/BigCard.vue';

async function waitMs(ms) {
  return new Promise((r) => {
    setTimeout(() => {
      r();
    }, ms);
  });
}

const logoCompany = [
  '日揚科技股份有限公司',
  '瑞儀光電(股)公司',
  '國巨電子(股)公司',
  '瀚宇彩晶科技(股)公司',
  '台灣積體電路製造(股)公司',
  '可成科技（股）公司',
  '華東科技股份有限公司',
  '新應材股份有限公司',
  '旗勝科技股份有限公司',
  '旭暉應用材料股份有限公司',
  '邦拓生物科技(股)公司',
  '大江生醫股份有限公司',
  '大成不銹鋼工業(股)公司',
  '嘉義鋼鐵股份有限公司',
  '馬玉山食品工業(股)公司',
  '欣全實業股份有限公司',
  '台橡股份有限公司',
  '國立成功大學',
  '國立中央大學',
  '國立海洋科技大學',
];

export default {
  name: 'Home',
  components: {
    workflow: WorkFlow,
    'big-card': BigCard,
    LogoSvg,
  },
  data() {
    return {
      photoAnimateRight: true,
      photoSplitterStyle: {},
      photoLeftStyle: {},
      photoRightStyle: {},
      splitterPos: 50,
      dragging: false,
      endPhoto: false,

      logoProjects: {},
      logoCompany,
      logoPos: {},
      animateStep: 'step0',
      logoWidth: 5,
      logoHeight: 4,
      bigCardInfo: [
        {
          title: '專業規劃分工',
          image: 'home-card-1.png',
          background: '#DEDDDD',
          desc: ['我們提供豐富的工程選項，', '想要協助您解決各式各樣的問題。'],
          page: 'business',
        },
        {
          title: '經驗豐富可靠',
          image: 'home-card-2.png',
          background: '#BACF39',
          desc: [
            '擁有與多家國際規模的公司合作，',
            '紮實豐富的經驗是我們的優勢。',
          ],
          shift: 46,
          page: 'projects',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['animateFinish', 'played']),
  },
  methods: {
    startDrag() {
      this.dragging = true;
    },
    endDrag() {
      this.dragging = false;
      const pos = this.splitterPos;
      this.photoSplitterStyle = {
        left: `${pos}%`,
      };
      this.photoLeftStyle = {
        width: `${pos}%`,
      };
      this.photoRightStyle = {
        width: `${100 - pos}%`,
      };
    },
    doDrag(e) {
      if (!this.dragging) {
        return;
      }
      e.preventDefault();
      let x;
      const width = document.body.clientWidth;
      if (e.type === 'touchmove') {
        const touch = e.touches[0] || e.originalEvent.touches[0]
          || e.originalEvent.changedTouches[0];
        x = touch.pageX;
      } else {
        x = e.clientX;
      }
      const pos = parseInt(((x * 100) / width), 10);
      this.splitterPos = pos;
      this.photoSplitterStyle = {
        left: `${pos}%`,
        transition: 'none',
      };
      this.photoLeftStyle = {
        width: `${pos}%`,
        transition: 'none',
      };
      this.photoRightStyle = {
        width: `${100 - pos}%`,
        transition: 'none',
      };
    },
    ...mapMutations(['setAnimateFinish']),
    async doAnimate() {
      this.setAnimateFinish(false);
      this.animateStep = 'step0';
      await waitMs(100);
      this.animateStep = 'step1';
      await waitMs(2200);
      this.animateStep = 'step2';
      await waitMs(2200);
      this.animateStep = 'step3';
      this.setAnimateFinish(true);
      await waitMs(2000);
      this.animateStep = 'step4';
    },
    checkPos() {
      this.setWorkflow();
    },
    setWorkflow() {
      const rect = this.$refs.workflow.$el.getBoundingClientRect();
      this.$refs.workflow.setShow(rect.y + 400 < window.innerHeight);
    },
    async loadLogoPos() {
      const data = await GetCompanyLogoPos();
      this.logoPos = data.data;
      const projects = await GetCompanyLogoProjects();
      this.logoProjects = projects.data;
    },
    goProject(i, j) {
      const idx = (i - 1) * this.logoWidth + (j - 1);
      const companyName = this.logoCompany[idx];
      const projects = this.logoProjects[companyName];
      if (companyName === '旭暉應用材料股份有限公司') {
        projects.push(...(this.logoProjects['百旭應用材料(股)公司'] || []));
        projects.push(...(this.logoProjects['義強科技(股)公司'] || []));
        projects.push(...(this.logoProjects['龍德科技(股)公司'] || []));
      } else if (companyName === '日揚科技股份有限公司') {
        projects.push(...(this.logoProjects['德揚科技(股)公司'] || []));
      }
      this.$root.$emit('show-projects', { title: companyName, projects });
      // const shift = this.logoPos[this.logoCompany[idx]];
      // if (shift !== undefined) {
      //   console.log(shift);
      //   this.$router.push(`/projects?shift=${shift}`);
      // }
    },
    async doPhotoAnimate() {
      // eslint-disable-next-line no-constant-condition
      while (!this.endPhoto) {
        // eslint-disable-next-line no-await-in-loop
        await waitMs(200);
        if (!this.dragging) {
          let pos = this.splitterPos;
          pos -= (pos % 10);
          let waitMoment = false;
          if (this.photoAnimateRight) {
            pos += 10;
            if (pos >= 100) {
              this.photoAnimateRight = false;
              waitMoment = true;
            }
          } else {
            pos -= 10;
            if (pos <= 0) {
              this.photoAnimateRight = true;
              waitMoment = true;
            }
          }
          if (pos === 50) {
            waitMoment = true;
          }
          this.splitterPos = pos;
          this.photoSplitterStyle = {
            left: `${pos}%`,
          };
          this.photoLeftStyle = {
            width: `${pos}%`,
          };
          this.photoRightStyle = {
            width: `${100 - pos}%`,
          };
          if (waitMoment) {
            // eslint-disable-next-line no-await-in-loop
            await waitMs(3000);
          }
        }
      }
    },
  },
  unmounted() {
    this.endPhoto = true;
  },
  mounted() {
    this.$setTitle('頂鑫科技工程有限公司/宥陞工程有限公司');
    if (!this.played) {
      this.doAnimate();
    } else {
      this.animateStep = 'step4';
    }
    this.loadLogoPos();
    this.doPhotoAnimate();
  },
};
</script>

<style lang="scss" scoped>
.home {
  overflow-y: scroll;
  overflow-y: overlay;
  overflow-x: hidden;
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 0px;
  transition: height 2s ease-in-out;
  &.animate {
    overflow: visible;
    height: 100vh;
    position: relative;
    margin-top: 80px;
    &::before {
      content: ' ';
      width: 100vw;
      height: 80px;
      position: absolute;
      top: -80px;
      left: 0;
      background: #bacf39;
      z-index: -1;
    }
  }
  .main-part {
    pointer-events: auto;
    position: relative;
    flex: 0 0 calc(100vh - 80px);
    max-height: 780px;
    transition: all 2s ease-in-out;
    .animate {
      position: absolute;
      top: 0px;
      left: 0;
      // background-image: url('/images/animate-tmp.png');
      // background-size: cover;
      background: #bacf39;
      height: 100vh;
      width: 100vw;
      transition: all 0.2s ease-in-out;
      transition-delay: 2s;
      overflow: hidden;
      a {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: rotate(-45deg) translateX(-50%);
        .arrow {
          width: 16px;
          height: 16px;
          border-left: 3px solid white;
          border-bottom: 3px solid white;
          transition: all 2s ease-in-out;
        }
      }
    }
    .data {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      padding-bottom: 186px;
      padding-left: 6vw;
      background: linear-gradient(to right, #bacf39ff 0%, #bacf3900 100%);
      transition: all 2s ease-in-out;
      .title {
        font-size: 60px;
        color: white;
        font-weight: 700;
      }
      .sub-title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.9px;
        color: white;
      }
      .button {
        border-radius: 12px;
        background: #d9d9d9;
        width: 172px;
        height: 55px;
        font-weight: 700;
        font-size: 18px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: #282825;
          color: #dedddd;
        }
      }
    }

    &.show-animate {
      pointer-events: none;
      max-height: 100vh;
      flex: 0 0 100vh;
      .animate {
        height: 100vh;
        max-height: 100vh;
        top: 0;
        .arrow {
          opacity: 0.01;
        }
      }
      .data {
        transform: translateX(-100%);
      }
    }
  }
  .photos {
    // origin is 1034px;
    flex: 0 0 786px;
    overflow: hidden;
    .row {
      position: relative;
      flex: 0 0 786px;
      // transition: all .3s linear;
      .photos-title {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        border-radius: 30px;
        border: 1px solid var(--lightgreen, #BACF39);
        background: #F0F0F0;
        padding: 15px 64px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        color: var(--text-color, #282825);
        text-align: center;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 119%; /* 23.8px */
        letter-spacing: 0.3px;
      }
      .splitter-container {
        fill: rgba(255, 255, 255, 0.3);

        stroke-width: 2px;
        stroke: #fff;

        backdrop-filter: blur(7.5px);
        position: absolute;
        top: 75%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        border-radius: 30px;
        transition: all .2s linear;
        cursor: pointer;
        user-drag: none;
        user-select: none;
      }
      .photo-container {
        position: absolute;
        top: 0;
        overflow: hidden;
        height: 786px;
        transition: all .2s linear;
        &.left {
          left: 0;
          width: 50%;
          img {
            left: 0;
          }
        }
        &.right {
          right: 0;
          width: 50%;
          img {
            right: 0;
          }
        }
        img {
          object-fit: cover;
          object-position: 50% 50%;
          width: 100vw;
          position: absolute;
          top: 0;
        }
      }
    }
    .photo-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: Noto Sans TC;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 129%; /* 64.5px */
      letter-spacing: 3.5px;
      color: white;
    }
  }

  .logo-wall {
    background: linear-gradient(
      180deg,
      #bacf39 0%,
      rgba(217, 217, 217, 0) 167px
    );
    .logos {
      max-width: 1054px;
      padding-top: 50px;
      justify-content: flex-start;
      .logo-cell {
        overflow: hidden;
        aspect-ratio: 1 / 1;
        flex: 1 0 209px;
        max-width: 209px;
        border: 1px solid #dedddd;
        margin-left: -1px;
        margin-top: -1px;
        padding: 12px;
        .logo-table-img {
          width: 100%;
          object-fit: contain;
          opacity: 0.8;
          transition: all 0.3s ease-in-out;
          filter: saturate(0);
          &:hover {
            opacity: 1;
            transform: translate(0, 0);
            filter: saturate(1);
          }
        }
      }
    }
  }

  .logo-table {
    width: calc(100vw - 40px);
    max-width: 1052px;
    flex: 0 0 auto;
    .logo-table-row {
      &:last-child {
        .logo-table-cell {
          border-bottom: 1px solid #dedddd;
        }
      }
      .logo-table-cell {
        overflow: hidden;
        aspect-ratio: 1 / 1;
        flex: 0 0 20%;
        max-width: 209px;
        border-top: 1px solid #dedddd;
        border-left: 1px solid #dedddd;
        padding: 12px;
        &:last-child {
          border-right: 1px solid #dedddd;
        }
        .logo-table-img {
          object-fit: contain;
          flex: 0 0 80%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .home {
    .main-part {
      .data {
        gap: 0 !important;
        text-align: left;
        .title {
          font-size: 40px;
        }
      }
    }

    .photos {
      flex: 0 0 283px;
      .row {
        flex: 0 0 283px;
        .photos-title {
          padding: 15px 28px;
          font-size: 16px;
          white-space: nowrap;
        }
        .splitter-container {
          fill: rgba(255, 255, 255, 0.3);

          stroke-width: 2px;
          stroke: #fff;

          backdrop-filter: blur(7.5px);
          position: absolute;
          top: 75%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
          border-radius: 30px;
          transition: all .2s linear;
          cursor: pointer;
          user-drag: none;
          user-select: none;
        }
        .photo-container {
          position: absolute;
          top: 0;
          overflow: hidden;
          height: 786px;
          transition: all .2s linear;
          &.left {
            left: 0;
            width: 50%;
            img {
              left: 0;
            }
          }
          &.right {
            right: 0;
            width: 50%;
            img {
              right: 0;
            }
          }
          img {
            object-fit: cover;
            object-position: 50% 50%;
            width: 100vw;
            position: absolute;
            top: 0;
          }
        }
      }
      .photo-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: Noto Sans TC;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 129%; /* 64.5px */
        letter-spacing: 3.5px;
        color: white;
      }
    }
    .logo-wall {
      padding-left: 20px;
      padding-right: 20px;
      .logos {
        .logo-cell {
          flex: 0 0 33%;
          max-width: 33%;
          .logo-table-img {
            width: 100%;
          }
        }
      }
    }
    .big-cards {
      flex-wrap: wrap;
    }
    // animation part in rwd

    .main-part {
      .animate {
        .animate-logo {
          width: 161px;
          height: 161px;
        }
        .animate-name {
          width: 161px;
        }
        .animate-name-tw {
          position: absolute;
          bottom: 30px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.home {
  overflow-x: hidden;
  overflow-y: visible;
  .main-part {
    .animate {
      position: relative;
      .animate-logo {
        margin-top: -40px;
        color: #f3f6de;
        transition: all 2s ease-in-out;
        overflow: visible;
        g {
          transition: all 2s ease-in-out;
        }
      }
      .animate-name {
        transition: all 2s ease-in-out;
      }
      .animate-name-tw {
        transition: all 2s ease-in-out;
      }
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: transparent;
      }
      .right-bg {
        img {
          width: 408px;
        }
        position: absolute;
        opacity: 0.001;
        top: 0;
        left: calc(50% + 140px);
        transform-origin: 50% 50vh;
        transform: rotate(-45deg) translate(0, 300px);
        transition: all 2s ease-in-out;
      }
      .left-bg {
        img {
          width: 408px;
        }
        position: absolute;
        opacity: 0.001;
        top: 0;
        left: calc(50% - 558px);
        transform-origin: 50% 50vh;
        transform: rotate(-45deg) translate(0, 300px);
        transition: all 2s ease-in-out;
      }
      &.step0 {
        .animate-logo {
          opacity: 0.3;
          transform-origin: 50% 50%;
          transform: translate(0, -50vh) rotate(-90deg);
        }
        #logo-r {
          transform-origin: 0 0;
          transform: translate(40px, 0);
        }
        #logo-t {
          transform: translate(0, -40px);
        }
        #logo-l {
          transform: translate(-40px, 0);
        }
        #logo-b {
          transform: translate(0, 40px);
        }
        .animate-name {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .animate-name-tw {
          opacity: 0.01;
          transform: translate(0, 200px);
        }
      }
      &.step1 {
        .animate-logo {
          opacity: 1;
          transform: rotate(0) translate(0, 0);
          #logo-r {
            transform: translate(0, 0) scale(1);
          }
          #logo-t {
            transform: translate(0, -0);
          }
          #logo-l {
            transform: translate(-0, 0);
          }
          #logo-b {
            transform: translate(0, 0);
          }
        }
      }
      &.step2 {
        .animate-logo {
          color: #595757;
          transition: all 0.5s ease-in-out;
        }
        .animate-name {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .animate-name-tw {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
      }
      &.step3 {
        .animate-logo {
          color: #595757;
          transition: all 2s ease-in-out;
          transform: translate(-800px, -800px);
        }
        .animate-name {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .animate-name-tw {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .right-bg {
          opacity: 1;
          left: calc(50% + 140px);
          transform: rotate(-45deg) translate(0, -600px);
        }
        .left-bg {
          opacity: 1;
          left: calc(50% - 558px);
          transform: rotate(-45deg) translate(0, -600px);
        }
      }
      &.step4 {
        .animate-logo {
          color: #595757;
          transition: all 2s ease-in-out;
          transform: translate(-800px, -800px);
        }
        .animate-name {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .animate-name-tw {
          opacity: 0.01;
          transform: translate(0, 300px);
        }
        .right-bg {
          opacity: 1;
          left: calc(50% + 140px);
          transform: rotate(-45deg) translate(0, -600px);
          animation: 20s linear 0s infinite go;
        }
        .left-bg {
          opacity: 1;
          left: calc(50% - 558px);
          transform: rotate(-45deg) translate(0, -600px);
          animation: 20s linear 0s infinite go;
        }
      }
    }
  }
}
@keyframes go {
  0% {
    // transform: rotate(-45deg) translate(0, 2050px);
    transform: rotate(-45deg) translate(0, -600px);
  }
  100% {
    transform: rotate(-45deg) translate(0, -3275px);
  }
}
</style>
