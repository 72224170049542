<template lang="pug">
.project-row.flex.column.p-24(:class="{'expanded': expanded}")
  .show-row.flex.align-stretch
    .id.flex.column
      .id-tag {{ data?.id }}
    .info.flex.column.align-start.flex-1.ml-24
      .tags {{ tags }}
      .name {{ data?.name }}
      .place {{ data?.place }}
  .expanded-part.flex.align-center.has-animate
    .empty-id.rwd-hide
    img.image.ml-24(:src="data?.image", v-if="data.image !== ''")
    .infos.flex.column.ml-24
      .row.flex.gap-16(v-for="col in columns", :key="col.key", v-if="showData[col.key] !== ''")
        .title {{ col.text }}
        .value
          template(v-if="col.key === 'industry' && showData[col.key] === '生技廠'") 生技/食品廠
          template(v-else) {{ showData[col.key] || '' }}
      .row.flex.gap-16(v-for="col in (data.custom || [])", :key="col.key", v-if="col.value !== ''")
        .title {{ col.title }}
        .value {{ col.value }}
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tags() {
      return (this.data?.items || []).join('、');
    },
    showData() {
      return {
        ...this.data,
        tags: this.tags,
      };
    },
  },
  data() {
    return {
      expanded: true,
      columns: [
        { text: '工程項目', key: 'tags' },
        { text: '地點', key: 'place' },
        { text: '產業類別', key: 'industry' },
      ],
    };
  },
  mounted() {
    this.$on('expand', () => {
      this.expanded = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.project-row {
  border-radius: 16px;
  background: var(--white, #FFF);
  .show-row {
    .id {
      flex: 0 0 60px;
      .id-tag {
        padding: 4px 12px;
        color: var(--lightgreen, #BACF39);
        background: var(--lightgreen-20, #F3F6DE);
      }
    }
    .info {
      flex: 1;
      .tags {
        color: var(--lightgreen, #BACF39);
        font-family: Noto Sans TC;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.7px;
      }
      .name {
        font-family: Noto Sans TC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.2px;
      }
      .place {
        font-family: Noto Sans TC;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.9px;
      }
    }
  }
  &.expanded {
    .expanded-part {
      margin-top: 24px;
      max-height: 500px;
    }
  }
  .expanded-part {
    margin-top: 0;
    max-height: 0;
    overflow: hidden;
    .empty-id {
      flex: 0 1 60px;
    }
    .image {
      flex: 0 0 320px;
      min-height: 160px;
      max-height: 160px;
      aspect-ratio: 2 / 1;
      object-fit: cover;
    }
    .infos {
      .row {
        .title {
          color: var(--darkgray-40, #BCBBBB);
          font-family: Noto Sans TC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 163%; /* 22.82px */
        }
        .value {
          font-family: Noto Sans TC;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 163%; /* 22.82px */
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .project-row {
    .show-row {
      .info {
        .tags {
          text-align: left;
        }
        .name {
          text-align: left;
          font-size: 20px;
        }
        .place {
          text-align: left;
        }
      }
    }
    .expanded-part {
      flex-wrap: wrap;
      row-gap: 20px;
      .image {
        flex: 1 0 300px;
        margin-left: 0 !important;
      }
      .infos {
        margin-left: 0 !important;
        gap: 12px;
      }
    }
  }
}
</style>
