<template lang="pug">
#app.flex.column(ref="app")
  page-header
  router-view.content(v-if="ready")
  .project-viewer.flex.align-center.justify-center(v-if="projects !== undefined")
    .logo-project-container.flex.column.overflow-hidden
      .close-icon.clickable(@click="closeProjects")
      .title.flex.align-center.justify-start {{ title }}
      .projects.flex.column.overflow-overlay
        project-card(v-for="(p, idx) in projects", :key="idx", :data="p", ref="card")
</template>

<script>
import { mapMutations } from 'vuex';
import { GetCompanyInfo } from '@/api/company';
import ProjectCard from '@/components/projects/ProjectRow.vue';
import PageHeader from './components/PageHeader.vue';

export default {
  components: {
    PageHeader,
    'project-card': ProjectCard,
  },
  watch: {
    $route(value) {
      console.log(value);
      if (value.hash === '') {
        this.$refs.app.scroll(0, 0);
      }
    },
    ready() {
      if (this.ready) {
        const { hash } = document.location;
        if (hash === '#contact') {
          window.location.hash = '';
          setTimeout(() => {
            window.location.hash = '#contact';
          }, 500);
        }
      }
    },
  },
  data() {
    return {
      ready: false,
      projects: undefined,
      title: '',
    };
  },
  methods: {
    ...mapMutations(['setInfo', 'setIntro']),
    async loadInfo() {
      const rsp = await GetCompanyInfo();
      this.setInfo(rsp.data);
      this.setIntro(rsp.data);
      this.$nextTick(() => {
        this.ready = true;
      });
    },
    closeProjects() {
      this.projects = undefined;
    },
  },
  mounted() {
    this.loadInfo();
    this.$root.$on('show-projects', ({ title, projects }) => {
      this.title = title;
      this.projects = projects.map((p) => ({
        id: p.no,
        name: p.customer_name,
        place: p.location,
        items: p.items,
        industry: p.industry,
        image: p.image === '' ? '' : `/api/image/${p.image}`,
        custom: Object.keys(p.custom || {}).map((k) => ({
          title: k,
          value: p.custom[k],
        })),
      }));
    });
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-y: overlay;
  .project-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.50);
    z-index: 101;
    .logo-project-container {
      width: calc(100vw - 40px);
      height: calc(100vh - 40px);
      max-width: 1020px;
      max-height: 704px;
      background: white;
      border-radius: 16px;
      border: 1px solid #DEDDDD;
      padding: 50px;
      position: relative;
      .title {
        color: var(--text-color, #282825);
        font-family: Noto Sans TC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
      }
    }

    .close-icon {
      border: 1px solid #BACF39;
      border-radius: 30px;
      height: 60px;
      width: 60px;
      position: absolute;
      right: 24px;
      top: 24px;
      &::before {
        content: ' ';
        position: absolute;
        background: #BACF39;
        width: 24px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        content: ' ';
        position: absolute;
        background: #BACF39;
        width: 24px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: all .3s ease-in-out;
      }
    }
  }
}
.content {
  width: 100vw;
  max-width: 100vw;
  flex: 1;
  flex: 0 0 auto;
  background: white;
}
</style>
