import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import FormInput from '@/components/FormInput.vue';
import FormSelect from '@/components/FormSelect.vue';
import FormButton from '@/components/FormButton.vue';
import BlockTitle from '@/components/BlockTitle.vue';
import PageTitleBlock from '@/components/PageTitleBlock.vue';
import PageFooter from './components/PageFooter.vue';

import '@/assets/styles/global.scss';

Vue.config.productionTip = false;
Vue.component('form-input', FormInput);
Vue.component('form-select', FormSelect);
Vue.component('form-button', FormButton);
Vue.component('block-title', BlockTitle);
Vue.component('page-block-title', PageTitleBlock);
Vue.component('page-footer', PageFooter);

Vue.use({
  install(v) {
    // eslint-disable-next-line no-param-reassign
    v.prototype.$setTitle = (title) => {
      if (document.title !== title) {
        document.title = title;
      }
    };
    // eslint-disable-next-line no-param-reassign
    v.prototype.$waitMs = (ms) => new Promise((r) => {
      setTimeout(() => {
        r();
      }, ms);
    });
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
