<template lang="pug">
.workflow.flex.column.align-center(:class="{'show': show}")
  block-title.block-title(title="工作流程", small)
  //- .workflow-title.flex.column.gap-10
  //-   .text 工作流程
  //-   .bottom-line
  img.workflow-img.mt-72.rwd-hide(src='/images/workflow.png')
  .rwd-only.mt-40
    img.ellipse-image(src='/images/title-background.svg')
    img.workflow-img(src='/images/workflow-rwd.png')
  .info-card.card-style.mt-38.p-20.text-left
    span 本公司擁有專業系統設計能力與豐富工程經驗的團隊，
    span 我們秉著『快速、專業、確實』的方式，由工程之預算評估、
    span 系統規劃設計、專業施工、標準驗收到移交系統給客戶，
    span 在各階段皆能提供給客戶最高的滿意度，
    span 並秉著服務至上為最終目標。
  .steps.flex.align-center.wrap.gap-30.mt-58
    workflow-step(
      v-for="(step, idx) in steps", :key="idx"
      :step="idx + 1",
      :title="step.title",
      :has-more="step.hasMore || false"
      :more="step.more"
      )
</template>

<script>
import WorkFlowStep from './WorkFlowStep.vue';

export default {
  name: 'workflow',
  components: {
    'workflow-step': WorkFlowStep,
  },
  data() {
    return {
      show: false,
      steps: [
        { title: '了解客戶需求' },
        { title: '現場會勘及 工程討論' },
        {
          title: '圖面設計規劃',
          hasMore: true,
          more: [
            '系統設計、節能優化',
            '施工圖繪製及套圖',
            '設備選型',
            '設備交期及施工工期評估',
            '工程預算評估',
          ],
        },
        { title: '報告工程系統 整體規劃與報價', hasMore: false },
        {
          title: '簽訂合約',
          hasMore: true,
          more: [
            '付款方式約定',
            '工期約定',
          ],
        },
        {
          title: '工程執行',
          hasMore: true,
          more: [
            '設備材料與施工圖送審',
            '合格供應商審核與採購',
            '設備材料進場、器材查驗',
            '施工協調、問題討論',
            '工程進度管理',
            '工程品質管理',
            '施工現場環安衛管理',
          ],
        },
        {
          title: '完工',
          hasMore: true,
          more: [
            '測試運行及設備調整',
            '驗收',
            '落實客戶教育訓練',
            '竣工資料、操作及保養手冊移交',
            '保固及維修保養',
          ],
        },
      ],
    };
  },
  methods: {
    setShow(val) {
      this.show = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow {
  background: #F0F0F0;
  .rwd-only {
    position: relative;
    height: 860px;
    flex: 0 0 860px;
    .workflow-img {
      position: relative;
      width: 100%;
      min-width: 0;
      max-width: 375px;
      z-index: 2;
      padding: 0 40px;
    }
    .ellipse-image {
      position: absolute;
      top: calc(50% - 80px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1148px;
      z-index: 1;
    }
  }
  .block-title {
    transition: all .3s ease-in-out;
    transform: translateX(100%);
    opacity: .01;
  }
  .workflow-img {
    width: 100%;
    min-width: 800px;
    max-width: 1148px;
    transition: all 1s ease-in-out;
    transform: translateY(200px);
    opacity: .01;
  }
  &.show {
    .block-title {
      transform: translate(0, 0);
      opacity: 1;
    }
    .workflow-img {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .info-card {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%; /* 26.88px */
  }
  .steps {
    width: 1000px;
    row-gap: 65px !important;
    justify-content: center;
  }
}

@media only screen and (max-width: 800px) {
  .info-card {
    margin: 0;
    width: 100vw;
    border-radius: 0;
    padding-left: 47px !important;
    padding-right: 40px !important;
  }
  .workflow {
      .steps {
      padding: 20px !important;
      max-width: 100vw;
      row-gap: 25px !important;
    }
  }
}
</style>
