import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://bat.bearathome.com.tw/api',
  // baseURL: 'http://localhost:8000',
  baseURL: '/api',
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'X-Organization': 'MTY5NDQ0NjAzNTgwOTkzNTM2MA==',
  },
});

export async function GetCompanyInfo() {
  const rsp = await instance.get('/company/info');
  return rsp.data;
}

export async function GetCompanyBusiness() {
  const rsp = await instance.get('/company/business');
  return rsp.data;
}

export async function GetCompanySEO() {
  const rsp = await instance.get('/company/seo');
  return rsp.data;
}

export async function GetCompanyProjects(category, page, limit) {
  const rsp = await instance.get('/company/projects', {
    params: {
      category,
      page,
      limit,
    },
  });
  return rsp.data;
}

export async function GetCompanyLogoPos() {
  const rsp = await instance.get('/company/logos');
  return rsp.data;
}

export async function GetCompanyLogoProjects() {
  const rsp = await instance.get('/company/logo-projects');
  return rsp.data;
}

export async function AddCompanyContact(info) {
  const rsp = await instance.post('/company/contact', info);
  return rsp.data;
}
