<template lang="pug">
.page-header.flex.align-center.justify-space-between(
  :class="{'hide': !animateFinish, 'animate': needAnimate}")
  .logo.clickable(@click="goHome")
  .menus.flex.align-center.gap-54.rwd-hide
    .menu.clickable(
      v-for="page in pages", :index="page.path"
      @click="goPage(page, $event)")
      a(:href="page.path", @click="goPage(page, $event)") {{ page.text }}
    .menu.clickable.flex.align-center
      a(href="#contact", @click="showMobileMenu = false") 聯絡我們
  .menu-icon-contaner.rwd-only.clickable.flex.align-center(
      @click="showMobileMenu = !showMobileMenu")
    .menu-icon(
      :class="{'active': showMobileMenu}")
  .rwd-menu.rwd-only.has-animate.flex.column.align-center(
    :class="{'active': showMobileMenu}")
    .menu.clickable.flex.align-center(
      v-for="page in pages", :index="page.path"
      @click="goPage(page, $event)") {{ page.text }}
    .menu.clickable.flex.align-center
      a(href="#contact", @click="showMobileMenu = false") 聯絡我們
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'page-header',
  data() {
    return {
      pages: [
        { text: '公司介紹', path: '/introduction' },
        { text: '營業項目', path: '/business' },
        { text: '工程實績', path: '/projects' },
        // { text: '聯絡我們', path: '#contact-us', anchor: true },
      ],
      showMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters(['animateFinish']),
    needAnimate() {
      return this.$router?.name === 'Home';
    },
  },
  methods: {
    goHome() {
      this.$router.push('/');
      this.showMobileMenu = false;
    },
    goPage(page, e) {
      e.preventDefault();
      e.stopPropagation();
      if (page.anchor) {
        const url = window.location.href;
        window.location.href = `#${page.path}`;
        window.history.replaceState(null, null, url);
        return;
      }

      this.$router.push(page.path);
      this.showMobileMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.page-header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  flex: 0 0 80px;
  padding-left: 23px;
  padding-right: 74px;
  background: white;
  max-height: 80px;
  overflow: hidden;
  &.animate {
    transition: all 2s ease-in-out;
  }
  &.hide {
    position: relative;
    transform: translateY(-100%);
    max-height: 0;
  }
  .logo {
    flex: 0 0 220px;
    height: 50px;
    background-image: url('/images/logo.svg');
    background-size: cover;
    margin-top: 1px;
  }
  .menus {
    .menu {
      transition: color .3s ease-in-out;
      &:hover {
        color: $color-primary;
      }
      a {
        color: #282825;
        text-decoration: none;
        transition: all .3s ease-in-out;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .page-header {
    padding-right: 28px;
    .logo {
      margin-bottom: 2px;
    }
    .menu-icon-contaner {
      height: 100%;
      flex: 0 0 32px;
      .menu-icon {
        width: 32px;
        height: 2px;
        background: #595757;
        position: relative;
        transition: all .3s ease-in-out;
        &::before, &::after {
          content: ' ';
          width: 100%;
          height: 2px;
          background: #595757;
          position: absolute;
          left: 0;
          transition: all .3s ease-in-out;
        }
        &::before {
          transform: translate(0, -10px);
        }
        &::after {
          transform: translate(0, 10px);
        }
        &.active {
          &::before {
            transform-origin: 50% 50%;
            transform: translate(0, 0) rotate(45deg);
          }
          &::after {
            transform-origin: 50% 50%;
            transform: translate(0, 0) rotate(-45deg);
          }
          background: transparent;
        }
      }
    }
  }
  .rwd-menu {
    width: 100vw;
    position: fixed;
    top: 80px;
    left: 0;
    max-height: 0px;
    overflow: hidden;
    z-index: 1000;
    background: white;
    &.active {
      max-height: calc(100vh - 80px);
      padding: 60px 0;
    }
    .menu {
      flex: 0 0 65px;
      a {
        color: #282825;
        text-decoration: none;
        transition: all .3s ease-in-out;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}
.menu {
  font-family: Noto Sans TC;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
}
</style>
