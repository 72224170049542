<template lang="pug">
.page-title-block.flex.column.align-center.justify-center(
  :class="{'simple': simple}"
  :style="style")
  .image(v-if="!hideEllipse")
  .card.content.flex.column.align-start
    block-title.mb-32.z-index-1(:title="this.title")
    .sub-title.text-left.z-index-1(v-if="subTitle !== ''") {{ subTitle }}
    .desc.text-left.mt-12.mb-12.z-index-1
      .line(v-for="(t, idx) in desc", :key="idx") {{ t }}
    img.icon-image.z-index-1(src="/images/title-icon.svg")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    desc: {
      type: Array,
      default: () => [],
    },
    imageSrc: {
      type: String,
      default: '',
    },
    hideEllipse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    simple() {
      return this.desc.length === 0;
    },
    style() {
      if (this.imageSrc !== '') {
        return {
          backgroundImage: `url(${this.imageSrc})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title-block {
  background: #F1F1F1;
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  height: 700px;
  .content {
    max-width: 828px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.15);
    padding: 42px;
  }
  &.simple {
    padding-top: 110px;
    padding-bottom: 110px;
    .block-title {
      margin-bottom: 0 !important;
    }
    .image {
      top: 50px;
      transform: translate(-50%, 0);
    }
    .content {
      background: transparent;
      box-shadow: none;
      align-items: center;
      .icon-image {
        margin-top: -20px;
      }
    }
  }
  .image {
    background-image: url('/images/title-background.svg');
    background-size: 1142px 608px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 608px;
    width: 1142px;
  }
  .sub-title {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
  }
  .desc {
    font-family: Noto Sans, Noto Sans TC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.16px;
  }
}
@media only screen and (max-width: 800px) {
  .page-title-block {
    .content {
      margin: 0 20px;
    }
  }
}
</style>
