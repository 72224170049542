<template lang="pug">
.form-select-container.align-start.flex.gap-14(
  ref="self"
  :class="{'column': !row, 'expand': expand}")
  .input-title.flex.align-center.justify-center {{ title }}
  .form-select.flex.column.pr-20
    .picked.flex.align-center.wrap.gap-8.p-8(@click="toggleExpand")
      template(v-for="v in innerValue")
        .picked-item.flex.align-center(v-if="v.pick", :key="v.value")
          .label {{ v.label }}
          .remove(@click="removePick(v, $event)")
    .arrow-icon(@click="toggleExpand")
    .options.flex.column.justify-start(ref="options", :class="{'use-tag': useTag}")
      .option.clickable.text-left.flex.align-center.justify-start(
        v-for="opt in innerValue", @click="choose(opt)"
        v-if="!opt.pick")
        .tag {{ opt.label }}
      .option(v-if="showOptLength === 0") 沒有其他選項
</template>

<script>
export default {
  name: 'form-select',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    row: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    useTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      innerValue: [],
    };
  },
  watch: {
    value() {
      this.updateInnerValue();
    },
    options() {
      this.updateInnerValue();
    },
  },
  computed: {
    showOptLength() {
      return this.innerValue.filter((v) => !v.pick).length;
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.innerValue.filter((v) => v.pick).map((v) => v.value));
    },
    removePick(v, e) {
      // eslint-disable-next-line no-param-reassign
      v.pick = false;
      e.stopPropagation();
      e.preventDefault();
      this.emitInput();
    },
    choose(option) {
      console.log(option);
      // eslint-disable-next-line no-param-reassign
      option.pick = !option.pick;
      this.emitInput();
    },
    toggleExpand() {
      this.expand = !this.expand;
      if (this.expand) {
        document.addEventListener('click', this.detectOutside);
      } else {
        document.removeEventListener('click', this.detectOutside);
      }
    },
    detectOutside(e) {
      if (e.target.className === 'tag') {
        return false;
      }
      if (!this.$refs.self.contains(e.target)) {
        this.toggleExpand();
      }
      return false;
    },
    updateInnerValue() {
      this.innerValue = this.options.map((opt) => ({
        ...opt,
        pick: this.value.indexOf(opt.value) >= 0,
      }));
    },
  },
  mounted() {
    this.updateInnerValue();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.form-select-container {
  position: relative;
  .input-title {
    line-height: 180%;
    vertical-align: middle;
    height: 45px;
  }
  .form-select {
    border: 1px solid $color-darkgray;
    background: transparent;
    line-height: 180%;
    font-size: 16px;
    min-width: 200px;
    flex: 1;

    min-height: 45px;
    .picked {
      flex: 0 0 45px;
      text-overflow: ellipsis;
      .picked-item {
        white-space: nowrap;
        border-radius: 40px;
        background: #C9D867;
        padding: 2px 20px 2px 30px;
        .remove {
          width: 16px;
          height: 16px;
          position: relative;
          margin-top: 2px;
          margin-left: 8px;
          &::before {
            content: ' ';
            width: 18px;
            border-bottom: 2px solid $color-darkgray;
            position: absolute;
            top: 7px;
            left: 0;
            transform-origin: 50% 1;
            transform: rotate(45deg);
          }
          &::after {
            content: ' ';
            width: 18px;
            border-bottom: 2px solid $color-darkgray;
            position: absolute;
            top: 7px;
            left: 0;
            transform-origin: 50% 1;
            transform: rotate(-45deg);
          }
        }
      }
    }
    overflow: hidden;
    transition: all .3s ease-in-out;

    .options {
      position: absolute;
      top: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all .3s ease-in-out;
      flex: 0 0 auto;
      width: calc(100% - 80px);
      &.use-tag {
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 12px;
        padding: 0;
        .option {
          margin: 0;
          &:last-child {
            margin: 0;
          }
        }
      }
      .option {
        margin: 20px 20px 0 20px;
        &:last-child {
          margin-bottom: 20px;
        }
        .tag {
          border-radius: 40px;
          background: #C9D867;
          padding: 2px 30px;
        }
      }
    }
  }
  &.column {
    align-items: stretch;
    .input-title {
      width: 100%;
      text-align: left;
    }
    .form-select {
      flex: 0 0 auto;
    }
  }
  .arrow-icon {
    border-bottom: 3px solid #7A7979;
    border-right: 3px solid #7A7979;
    min-width: 18px;
    min-height: 18px;
    flex: 0 0 18px;
    transform-origin: 50% 50%;
    transform: translateY(-15px) rotate(45deg);
    transition: all .3s ease-in-out;
    position: absolute;
    right: 16px;
    top: 50%;
  }

  &.expand {
    .form-select {
      overflow: visible;
    }
    .arrow-icon {
      transform: translateY(-5px) rotate(225deg);
    }
    .options {
      max-height: 100vh !important;
      border-top: none;
      border: 1px solid $color-darkgray;
      background: white;
      &.use-tag {
        padding: 12px;
      }
    }
  }
}
</style>
