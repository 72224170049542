<template lang="pug">
.form-button.flex.align-center.justify-center.clickable(:style="style") {{ title }}
</template>

<script>
export default {
  name: 'form-button',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '300px',
    },
    bgColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      const style = {
        width: this.width,
        height: '58px',
      };
      if (this.bgColor) {
        style.backgroundColor = this.bgColor;
      }
      if (this.color) {
        style.color = this.color;
      }
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.form-button {
  border: 1px solid $color-darkgray;
  &:hover {
    border: 1px solid var(--darkgray, #595757);
    background: var(--darkgray, #595757);
    color: white;
  }
}
</style>
