<template lang="pug">
.page-footer.flex.align-center.justify-center
  .anchor-node#contact
  .footer-content.flex.column
    .flex.align-start.wrap
      .left.flex.column.align-center
        .logo
      .right.flex.align-start.gap-38.wrap
        .block.flex.column.flex-1.mr-20.ml-20
          .title.flex.align-center.gap-40
            .text.font-size-20.bold 聯絡我們
            .split-line
          .row.flex.align-center.gap-52.wrap
            form-input.flex-1.mt-32(title="公司名稱", v-model="name")
            form-input.flex-1.mt-32(title="電話", v-model="mobile")
          .row.flex.align-center.gap-52.wrap
            form-input.flex-1.mt-32(title="聯絡人", v-model="contact")
            form-input.flex-1.mt-32(title="E-mail", v-model="email")
          .row.flex.align-center.gap-52.wrap
            form-select.flex-1.mt-32(
              use-tag
              row title="服務需求", :options="serviceOptions", v-model="service")
          .row.flex.align-center.gap-52.wrap
            form-input.flex-1(title="備註", v-model="memo").mt-48
          .row.flex.align-center.wrap
            form-button.flex-1(title="確定送出", @click.native="sendContact").mt-38
        .block.flex.column.flex-auto.gap-30.mr-20.ml-20.activity
          .title.flex.align-center.gap-40
            .text.font-size-20.bold 加入我們
            .split-line
          form-button.form-button(title="104人力銀行", width="100%", @click.native="go104")
          form-button.form-button(title="1111人力銀行", width="100%", @click.native="go1111")
          .title.flex.align-center.gap-40
            .text.font-size-20.bold 更多我們
            .split-line
          form-button.form-button(
            title="Facebook", width="100%", @click.native="goFB", bgColor="#0866FF", color="white")
    .flex.align-start.mt-100.wrap.contact-container
      .left.flex.column.align-center.copy-right
        .font-size-16 © TOP TEAM 2023
      .right.flex.align-start.contact
        .block.flex.column.flex-1.gap-22.mr-20.ml-20
          .title.flex.align-center.gap-40
            .text.font-size-20.bold 聯絡資訊
            .split-line
          .sub-blocks.flex.align-start.gap-46.wrap
            .sub-block.flex.column.align-start.line-height-180
              .bold 頂鑫科技工程有限公司
              .bold 宥陞工程有限公司
              .plain email:{{info.email}}
              .plain {{info.website}}
            .sub-block.flex.column.align-start.line-height-180
              template(v-for="office in (info?.offices || [])")
                .text
                  span.bold {{ office.name }} :&nbsp;
                  span.plain {{ office.address }}
                .plain 電話 : {{office.phone}}  傳真 : {{office.fax}}
                br
            .sub-block.flex.column.align-start.line-height-180
              template(v-for="office in (info?.chinaOffices || [])")
                .bold 中國據點 :&nbsp;
                .plain.pre-wrap {{ office.address }}
                .text.flex.align-center
                  .bold 電話 :&nbsp;
                  .plain {{ office.phone }}
                br
</template>

<script>
import { mapGetters } from 'vuex';
import { AddCompanyContact } from '@/api/company';

export default {
  name: 'page-footer',
  data() {
    return {
      serviceOptions: [
        { value: '潔淨室工程', label: '潔淨室工程' },
        { value: '中央空調工程', label: '中央空調工程' },
        { value: '高低壓電力工程', label: '高低壓電力工程' },
        { value: '給排水系統工程', label: '給排水系統工程' },
        { value: '消防系統工程', label: '消防系統工程' },
        { value: '空污/純廢水系統工程', label: '空污/純廢水系統工程' },
        { value: '製程管路工程', label: '製程管路工程' },
        { value: '節能改善工程', label: '節能改善工程' },
      ],

      name: '',
      mobile: '',
      contact: '',
      email: '',
      service: [],
      memo: '',
    };
  },
  computed: {
    ...mapGetters(['info']),
  },
  methods: {
    go104() {
      this.goSite('https://www.104.com.tw/company/1a2x6bjov3');
    },
    go1111() {
      this.goSite('https://www.1111.com.tw/corp/48721186/');
    },
    goFB() {
      this.goSite('https://www.facebook.com/topteamttte');
    },
    goSite(site) {
      window.open(site, '_blank');
    },
    async sendContact() {
      const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
      const emailRe = new RegExp(emailRule);
      if (this.name === '') {
        alert('請輸入公司名稱');
        return;
      }
      if (this.mobile === '') {
        alert('請輸入電話');
        return;
      }
      if (this.contact === '') {
        alert('請輸入聯絡人');
        return;
      }
      if (emailRe.exec(this.email) === null) {
        alert('請輸入有效的電子郵件');
        return;
      }
      if (this.service.length === 0) {
        alert('請選擇服務需求');
        return;
      }

      try {
        await AddCompanyContact({
          company_name: this.name,
          mobile: this.mobile,
          contact: this.contact,
          email: this.email,
          service: this.service,
          memo: this.memo,
        });
        this.reset();
        alert('送出成功');
      } catch (e) {
        console.log(e);
        alert('伺服器錯誤，請稍後再試');
      }
    },
    reset() {
      this.name = '';
      this.mobile = '';
      this.contact = '';
      this.email = '';
      this.service = [];
      this.memo = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.page-footer {
  width: 100vw;
  overflow-x: hidden;
  flex: 0 0 auto;
  background: #D9D9D9;
  padding-top: 45px;
  padding-bottom: 181px;
  padding-right: 31px;
  position: relative;
  .anchor-node {
    position: absolute;
    top: -80px;
  }
  .footer-content {
    flex: 0 1 1440px;
  }
  .left {
    flex: 0 0 320px;
  }
  .right {
    flex: 1;
    .title {
      .split-line {
        border-bottom: 4px solid $color-primary;
        flex: 1;
        margin-top: 2px;
      }
    }
    .activity {
      flex: 0 0 342px;
    }
    .pre-wrap {
      white-space: pre;
      text-align: left;
    }
  }

  .logo {
    background-image: url('/images/logo-large.svg');
    background-size: 161px 201px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 161px;
    height: 201px;
    margin-bottom: 76px;
  }
}
@media only screen and (max-width: 800px) {
  .page-footer {
    padding-bottom: 25px;
    padding-right: 0px;
    .left {
      flex: 0 0 100%;
      .logo {
        width: 100%;
      }
    }
    .right {
      .block {
        width: 100%;
        flex: 1;
        .row {
          row-gap: 0 !important;
        }
      }
      .activity {
        flex: 1 1 100%;
      }
    }
    .contact-container {
      margin-top: 60px !important;
      .block {
        .sub-blocks {
          margin-top: 11px;
          .sub-block {
            text-align: left;
          }
        }
      }
      .copy-right {
        order: 2;
        margin-top: 62px;
      }
      .contact {
        order: 1;
      }
    }
  }
}
</style>
