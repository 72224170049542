<template lang="pug">
.block-title.flex.column.gap-10(:class="{'small': small}")
  .text {{ title }}
  .bottom-line
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.block-title {
  .text {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 1.1px;
  }
  &.small {
    .text {
      font-size: 22px;
    }
  }
  .bottom-line {
    border-bottom: 5px solid #7A7979;
  }
}
</style>
