<template lang="pug">
.big-card.card-style.flex.column.pb-32(:style="style")
  .img(:style="imageStyle")
  .title.mt-48.mr-40.ml-40.text-left {{ title }}
  .desc-lines.flex.column.align-start.mt-18.mr-40.ml-40
    .desc(v-for="(line, idx) in desc", :key="idx") {{ line }}
  .button.clickable.mt-36.mr-40.ml-40.flex.align-center.justify-center.has-animate(
    @click="goPage"
  ) 了解更多
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: Array,
      required: true,
    },
    page: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: '',
    },
    shift: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    style() {
      return {
        background: this.background,
        marginTop: `${this.shift}px`,
      };
    },
    imageStyle() {
      return {
        backgroundImage: `url(/images/${this.image})`,
      };
    },
  },
  methods: {
    goPage() {
      if (this.page !== '') {
        this.$router.push(this.page);
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
  .big-card {
    max-width: 490px;
    overflow: hidden;
    .img {
      transform-origin: 50% 100%;
      transition: all .5s ease-in-out;
      flex: 0 0 280px;
      background-position: 50% 50%;
    }
    .title {
      font-family: Noto Sans TC;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 31.2px */
      letter-spacing: 0.36px;
    }
    .desc-lines {
      font-family: Noto Sans TC;
      font-size: 16px;
      font-weight: 400;
      line-height: 168%; /* 26.88px */
    }
    .button {
      border-radius: 35px;
      height: 58px;
      border: 1px solid #595757;
      &:hover {
        color: white;
        font-weight: 500;
        background: #595757;
      }
    }
    &:hover {
      .img {
        transform: scale(1.18);
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .big-card {
      max-width: calc(100vw - 40px);
      margin-top: 0 !important;
    }
  }
  </style>
