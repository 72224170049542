<template lang="pug">
.form-input-container.flex.column.gap-14
  .input-title {{ title }}
  input.form-input(
    @input="emitInput", v-model="innerValue")
</template>

<script>
export default {
  name: 'form-input',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerValue: '',
    };
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
  methods: {
    emitInput() {
      this.$emit('input', this.innerValue);
    },
  },
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.form-input-container {
  min-width: 200px;
  .input-title {
    width: 100%;
    text-align: left;
  }
  .form-input {
    border: none;
    border-bottom: 1px solid $color-darkgray;
    background: transparent;
    line-height: 180%;
    font-size: 16px;
  }
}
</style>
